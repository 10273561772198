
function Header() {
  return (
      <header className="App-header">
        Header!
      </header>
  );
}

export default Header;
